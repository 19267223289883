import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { layout: 'empty' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('@/views/TourPackages/Forms.vue')
  },
  {
    path: '/editForm/:formId',
    name: 'Edit Forms',
    component: () => import('@/views/TourPackages/EditForm.vue')
  },
  {
    path: '/cards',
    name: 'Cards',
    component: () => import('@/views/CardView.vue')
  },
  {
    path: '/tables',
    name: 'TourPackage',
    component: () => import('@/views/TourPackages/Tables.vue')
  },
  {
    path: '/ui-elements',
    name: 'UIElements',
    component: () => import('@/views/UIElements.vue')
  },
  {
    path: '/modal',
    name: 'Modal',
    component: () => import('@/views/Modal.vue')
  },
  {
    path: '/charts',
    name: 'Chart',
    component: () => import('@/views/ChartView.vue')
  },
  {
    path: '/blank',
    name: 'Blank',
    component: () => import('@/views/BlankView.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users/Users.vue')
  },
  {
    path: '/addusers',
    name: 'AddUsers',
    component: () => import('@/views/Users/AddUsers.vue')
  },
  {
    path: '/subagents',
    name: 'subagents',
    component: () => import('@/views/Subagents/Subagents.vue')
  },
  {
    path: '/addsubagent',
    name: 'addsubagent',
    component: () => import('@/views/Subagents/AddSubagent.vue')
  },
  {
    path: '/editsubagent/:formId',
    name: 'editsubagent',
    component: () => import('@/views/Subagents/EditSubagent.vue')
  },
  {
    path: '/priceCharges',
    name: 'priceCharges',
    component: () => import('@/views/PriceCharges/PriceCharges.vue')
  },
  {
    path: '/addpricecharges',
    name: 'addpricecharges',
    component: () => import('@/views/PriceCharges/AddPriceCharges.vue')
  },
  {
    path: '/editpricecharges/:formId',
    name: 'editpricecharges',
    component: () => import('@/views/PriceCharges/EditPriceCharges.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services/Services.vue')
  },
  {
    path: '/servicetype',
    name: 'ServiceType',
    component: () => import('@/views/ServiceType/ServiceType.vue')
  },
  {
    path: '/servicetypeforms',
    name: 'ServiceTypeForm',
    component: () => import('@/views/ServiceType/AddServiceType.vue')
  },
  {
    path: '/editServiceType/:formId',
    name: 'editServiceType',
    component: () => import('@/views/ServiceType/EditServiceType.vue')
  },
  {
    path: '/addservice',
    name: 'addservice',
    component: () => import('@/views/Services/AddService.vue')
  },
  {
    path: '/editservice/:formId',
    name: 'editservice',
    component: () => import('@/views/Services/EditService.vue')
  },
  {
    path: '/tourservice',
    name: 'tourservice',
    component: () => import('@/views/TourServices/TourService.vue')
  },
  {
    path: '/service-details',
    name: 'servicedetails',
    component: () => import('@/views/ServiceDetails/ServiceDetails.vue')
  },
  {
    path: '/service-detail-fields',
    name: 'servicedetailfields',
    component: () =>
      import('@/views/ServiceDetailFields/ServiceDetailFields.vue')
  },
  {
    path: '/service-detail-field-groups',
    name: 'servicedetailfieldgroups',
    component: () =>
      import('@/views/ServiceDetailFieldGroups/ServiceDetailFieldGroups.vue')
  },
  {
    path: '/user-statistics',
    name: 'userstatistics',
    component: () => import('@/views/UserStatistics/UserStatistics.vue')
  },
  {
    path: '/packages-statistics',
    name: 'packagesstatistics',
    component: () => import('@/views/PackagesStatistics/PackagesStatistics.vue')
  },
  {
    path: '/tourpackageservice',
    name: 'tourpackageservice',
    component: () => import('@/views/TourPackageService/TourPackageService.vue')
  },
  {
    path: '/addtourservices',
    name: 'addtourservices',
    component: () => import('@/views/TourServices/AddTourService.vue')
  },
  {
    path: '/add-service-details',
    name: 'addservicedetails',
    component: () => import('@/views/ServiceDetails/AddServiceDetails.vue')
  },
  {
    path: '/add-service-detail-fields',
    name: 'addservicedetailfields',
    component: () =>
      import('@/views/ServiceDetailFields/AddServiceDetailFields.vue')
  },
  {
    path: '/add-service-detail-field-groups',
    name: 'addservicedetailfieldgroups',
    component: () =>
      import('@/views/ServiceDetailFieldGroups/AddServiceDetailFieldGroups.vue')
  },
  {
    path: '/addtourpackageservices',
    name: 'addtourpackageservices',
    component: () =>
      import('@/views/TourPackageService/AddTourPackageService.vue')
  },
  {
    path: '/edittourservice/:formId',
    name: 'edittourservice',
    component: () => import('@/views/TourServices/EditTourService.vue')
  },
  {
    path: '/edit-service-details/:formId',
    name: 'editservicedetails',
    component: () => import('@/views/ServiceDetails/EditServiceDetails.vue')
  },
  {
    path: '/edit-service-detail-fields/:formId',
    name: 'editservicedetailfields',
    component: () =>
      import('@/views/ServiceDetailFields/EditServiceDetailFields.vue')
  },
  {
    path: '/edit-service-detail-field-groups/:formId',
    name: 'editservicedetailfieldgroups',
    component: () =>
      import(
        '@/views/ServiceDetailFieldGroups/EditServiceDetailFieldGruops.vue'
      )
  },
  {
    path: '/edittourpackageservice/:formId',
    name: 'edittourpackageservice',
    component: () =>
      import('@/views/TourPackageService/EditTourPackageService.vue')
  },
  {
    path: '/requestview',
    name: 'requestview',
    component: () => import('@/views/RequestView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/News/News.vue')
  },
  {
    path: '/newsUmrahRituals',
    name: 'newsUmrahRituals',
    component: () => import('@/views/News/NewsUmrahRituals.vue')
  },
  {
    path: '/newsPrayingRules',
    name: 'newsPrayingRules',
    component: () => import('@/views/News/NewsPrayingRules.vue')
  },
  {
    path: '/addnews',
    name: 'addnews',
    component: () => import('@/views/News/AddNews.vue')
  },
  {
    path: '/addprayers',
    name: 'addprayers',
    component: () => import('@/views/News/Prayers.vue')
  },
  {
    path: '/editnews/:formId',
    name: 'EditnewsVue',
    component: () => import('@/views/News/EditNews.vue')
  },
  {
    path: '/edituser/:formId',
    name: 'EdituserVue',
    component: () => import('@/views/Users/EditUser.vue')
  },
  {
    path: '/edituserprofile',
    name: 'edituserprofile',
    component: () => import('@/views/Users/EditUserProfile.vue')
  },
  {
    path: '/changepass',
    name: 'changepass',
    component: () => import('@/views/ChangeUserPass.vue')
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('@/views/Roles/AddRoles.vue')
  },
  {
    path: '/addroles',
    name: 'AddRoles',
    component: () => import('@/views/Roles/AddRoles.vue')
  },
  {
    path: '/editroles/:formId',
    name: '/EditRoles',
    component: () => import('@/views/Roles/EditRoles.vue')
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: () => import('@/views/Rooms/Rooms.vue')
  },
  {
    path: '/addrooms',
    name: 'addrooms',
    component: () => import('@/views/Rooms/AddRooms.vue')
  },
  {
    path: '/editRooms/:formId',
    name: 'EditRooms',
    component: () => import('@/views/Rooms/EditRooms.vue')
  },
  {
    path: '/roomfeatures',
    name: 'roomfeatures',
    component: () => import('@/views/Room-features/Room_features.vue')
  },
  {
    path: '/addroomfeatures',
    name: 'AddRoomFeatures',
    component: () => import('@/views/Room-features/AddRoomFeatures.vue')
  },
  {
    path: '/editroomfeatures/:formId',
    name: 'EditRoomFeatures',
    component: () => import('@/views/Room-features/EditRoomFeatures.vue')
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/views/Features/Features.vue')
  },
  {
    path: '/addfeatures',
    name: 'AddFeature',
    component: () => import('@/views/Features/AddFeature.vue')
  },
  {
    path: '/editfeature/:formId',
    name: 'EditFeature',
    component: () => import('@/views/Features/EditFeature.vue')
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import('@/views/Room-prices/Room_prices.vue')
  },
  {
    path: '/addroomprices',
    name: 'AddRoomPrice',
    component: () => import('@/views/Room-prices/AddRoomPrice.vue')
  },
  {
    path: '/editroomprices/:formId',
    name: 'EditRoomPrice',
    component: () => import('@/views/Room-prices/EditRoomPrice.vue')
  },
  {
    path: '/roomorders',
    name: 'roomorders',
    component: () => import('@/views/Room-orders/Room_orders.vue')
  },
  {
    path: '/addroomorders',
    name: 'AddRoomOrders',
    component: () => import('@/views/Room-orders/AddRoomOrders.vue')
  },
  {
    path: '/editroomorders/:formId',
    name: 'EditRoomOrders',
    component: () => import('@/views/Room-orders/EditRoomOrders.vue')
  },
  {
    path: '/room-order-items',
    name: 'room-order-items',
    component: () => import('@/views/Room-order-items/Room_order_items.vue')
  },
  {
    path: '/addroomorderitems/:formId',
    name: 'AddRoomOrderItems',
    component: () => import('@/views/Room-order-items/AddRoomOrderItems.vue')
  },
  {
    path: '/editroomorderitems/:formId',
    name: 'EditRoomOrderItems',
    component: () => import('@/views/Room-order-items/EditRoomOrderItems.vue')
  },
  {
    path: '/package-groups',
    name: 'MyPackages',
    component: () => import('@/views/Packages/MyPackages.vue')
  },
  {
    path: '/packages',
    name: 'PackageGroups',
    component: () => import('@/views/PackageGroups/PackageGroups.vue')
  },
  {
    path: '/package-stats',
    name: 'PackageStats',
    component: () => import('@/views/Packages/PackageStats.vue')
  },
  {
    path: '/sos-signal',
    name: 'sos-signal',
    component: () => import('@/views/SOS/SosSignal.vue')
  },
  {
    path: '/package-schedules',
    name: 'package-schedules',
    component: () => import('@/views/PackageSchedules/PackageSchedules.vue')
  },
  {
    path: '/add-package-schedules',
    name: 'add-package-schedules',
    component: () => import('@/views/PackageSchedules/AddPackageSchedules.vue')
  },
  {
    path: '/edit-package-schedules/:formId',
    name: 'edit-package-schedules',
    component: () => import('@/views/PackageSchedules/EditPackageSchedules.vue')
  },
  {
    path: '/orders-accounting',
    name: 'OrdersAc',
    component: () => import('@/views/Accounting/AccountingTable.vue')
  },
  { path: '/:pathMatch(.*)*', component: () => import('@/views/NotFound.vue') }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
